import { CloseOutlined } from '@mui/icons-material';
import { Alert, CircularProgress, Collapse, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios, { AxiosError } from 'axios';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../config/constants';
import strings from '../config/strings';
import userContext from '../context/user.context';

export default function Login() {
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const navigate = useNavigate();
  const auth = useContext(userContext);
  const [errors, setErrors] = useState<string[]>([]);
  const [showVerification, setShowVerification] = useState(false);
  const [emailToVerify, setEmailToVerify] = useState('');
  const [verificationCode, setEmailVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const startVerification = async (email: string) => {
    setEmailToVerify(email);
    await axios.post(`${constants.API_URL}/member/send_verification_email`, { email });
    setShowVerification(true);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    try {
      if (isLogin) {
        const response = await axios.post(`${constants.API_URL}/user/login`, {
          email: data.get('email') as string,
          password: data.get('password') as string,
        });
        if (!response.data.member?.expert_profile?._id) {
          setErrors(['Unable to login']);
        } else {
          localStorage.setItem(strings.$GH_TOKEN, response.data.token);
          localStorage.setItem(strings.$GH_REFRESH_TOKEN, response.data.member.refresh_token);

          auth.login(response.data.member._id, response.data.token, response.data.member.refresh_token);
          navigate('/expert');
        }
      } else {
        const response = await axios.post(`${constants.API_URL}/user/expert-register`, {
          email: data.get('email') as string,
          password: data.get('password') as string,
          phone: data.get('phone') as string,
          first_name: data.get('first_name') as string,
          last_name: data.get('last_name') as string,
        });
        localStorage.setItem(strings.$GH_TOKEN, response.data.token);
        localStorage.setItem(strings.$GH_REFRESH_TOKEN, response.data.member.token);
        startVerification(response.data.member.email);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 666) {
          startVerification(error.response.data.email);
        } else
          setErrors(
            error.response?.data.errors
              ?.map((error: any) => {
                return error['he'];
              })
              ?.join('\n') || [error.response?.data.message.he]
          );
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleVerify = useCallback(async () => {
    try {
      const response = await axios.post(`${constants.API_URL}/member/check_otp`, {
        email: emailToVerify,
        code: verificationCode,
      });
      if (response.status === 200) {
        auth.login(response.data.id, response.data.token, response.data.refresh_token);
        navigate('/expert');
      }
    } catch (error: any) {
      console.log(error);
      setErrors(error.response.data.errors || [error.response.data.message.he]);
    }
  }, [navigate, emailToVerify, verificationCode, auth]);
  return (
    <>
      <Collapse in={errors?.length > 0}>
        <Alert
          severity='error'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setErrors([]);
              }}>
              <CloseOutlined fontSize='inherit' />
            </IconButton>
          }>
          {errors}
        </Alert>
      </Collapse>

      <Container dir='rtl' component='main' maxWidth='xs'>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Box m={1}>
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              <Avatar sx={{ backgroundColor: 'secondary.main' }} src={require('../assets/grouphug_1024.png')} />
            )}
          </Box>

          <Typography component='h1' variant='h5'>
            {isLogin ? 'התחברות' : 'הרשמה'}
          </Typography>

          {showVerification ? (
            <Box display='flex' flexDirection='column' width={350}>
              <Typography variant='h6' textAlign='center'>
                שלחנו לך קוד אימות לאימייל
              </Typography>

              <TextField
                margin='normal'
                fullWidth
                variant='standard'
                inputMode='numeric'
                inputProps={{ style: { textAlign: 'center' } }}
                value={verificationCode}
                onChange={(e) => setEmailVerificationCode(e.target.value)}
              />

              <Button onClick={handleVerify} variant='outlined'>
                Verify
              </Button>
            </Box>
          ) : !isLogin ? (
            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                variant='standard'
                label='כתובת מייל'
                name='email'
                autoComplete='email'
                helperText='הכתובת שמוגדרת אצלנו במערכת'
                autoFocus
              />

              <TextField
                style={{ textAlign: 'right' }}
                margin='normal'
                required
                fullWidth
                id='first_name'
                label='שם פרטי'
                variant='standard'
                name='first_name'
                autoComplete='first_name'
              />
              <TextField
                margin='normal'
                required
                fullWidth
                id='last_name'
                label='שם משפחה'
                name='last_name'
                variant='standard'
              />
              <TextField required margin='normal' fullWidth id='phone' label='טלפון' name='phone' variant='standard' />
              <TextField
                style={{ textAlign: 'right' }}
                margin='normal'
                required
                fullWidth
                name='password'
                label='סיסמה'
                helperText='חייבת להכיל אותיות ומספרים, לפחות 6 תווים'
                type='password'
                id='password'
                autoComplete='current-password'
                variant='standard'
              />

              {/* <FormControlLabel control={<Checkbox value='remember' color='primary' />} label='Remember me' /> */}

              <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                {isLogin ? 'התחברות' : 'הרשמה'}
              </Button>
            </Box>
          ) : (
            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                label='כתובת מייל'
                id='email'
                name='email'
                autoComplete='email'
                variant='standard'
                autoFocus
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='סיסמה'
                type='password'
                id='password'
                variant='standard'
                autoComplete='current-password'
              />
              <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>
            </Box>
          )}
          <Grid item>
            <Link
              href='#'
              onClick={() => {
                setIsLogin((prev) => !prev);
              }}
              variant='body2'>
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
          <Button
            onClick={() => {
              navigate('/reset_password');
            }}>
            שכחת סיסמא?
          </Button>
        </Box>
      </Container>
      {/* </ThemeProvider> */}
    </>
  );
}
