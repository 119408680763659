import { DataGrid, DataGridProps, GridValueFormatterParams } from '@mui/x-data-grid';
import moment from 'moment';

const MeetingsTable = (props: { showZoomInfo?: boolean } & Partial<DataGridProps>) => {
  return (
    <DataGrid
      {...props}
      density='compact'
      autoHeight
      pageSize={10}
      rowsPerPageOptions={[10, 20, 50]}
      getRowId={(row) => row._id}
      disableColumnMenu
      columns={[
        {
          minWidth: 130,
          field: 'time',
          type: 'dateTime',
          headerName: 'תאריך',
          // valueGetter: ({ row }) => moment(row.time).format('DD.MM.YY'),
          valueFormatter: (value: GridValueFormatterParams<Date>) => moment(value.value).format('DD/MM/YY H:mm'),
        },

        { field: 'title', headerName: 'כותרת', sortable: true, width: 250 },
        { field: 'registered', headerName: 'רשומים', sortable: true, width: 70 },

        ...(props.showZoomInfo
          ? [
              { field: 'zoom', headerName: 'קישור זום', sortable: false, flex: 1 },
              { field: 'zoom_password', headerName: 'סיסמה', sortable: false, width: 100 },
            ]
          : []),
      ]}
      rows={props.rows || []}
    />
  );
};

export default MeetingsTable;
