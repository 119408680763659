import axios, { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import constants from '../config/constants';
import strings from '../config/strings';

let logoutTimer: NodeJS.Timeout;
axios.interceptors.response.use(
  (a: AxiosResponse) => {
    return a;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      return refreshTheToken(error);
    } else throw error;
  }
);
const refreshTheToken = async (error: AxiosError) => {
  const originalRequest: any = error.config;
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const refresh_token = localStorage.getItem(strings.$GH_REFRESH_TOKEN);
      const refresh_response = await axios.post(`${constants.API_URL}/user/refresh`, {
        refresh_token,
      });

      if (refresh_response.status === 201) {
        // 1) put token to LocalStorage
        localStorage.setItem(strings.$GH_TOKEN, refresh_response.data);
        // 2) Change Authorization header
        axios.defaults.headers.common['Authorization'] = `Bearer ${refresh_response.data}`;

        // 3) return originalRequest object with Axios.
        return axios({
          ...originalRequest,
          data: originalRequest.data ? JSON.parse(originalRequest.data) : undefined,
          headers: { Authorization: 'Bearer ' + refresh_response.data },
        });
      }
    } catch (error) {
      // useAuth.logout(dispatch);
    }
  }
};
export const useAuth = () => {
  const [token, setToken] = useState<string | null>();
  const [tokenExpirationDate, setTokenExpirationDate] = useState<Date | null>();
  const [userId, setUserId] = useState<string | null>();

  const login = useCallback((uid: string, token: string, refresh_token: string, expirationDate?: Date) => {
    setToken(token);
    setUserId(uid);
    const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 6000 * 60);
    setTokenExpirationDate(tokenExpirationDate);

    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
    localStorage.setItem(strings.$GH_REFRESH_TOKEN, refresh_token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, []);

  const refresh = useCallback(async (uid: string) => {
    const refresh_token = localStorage.getItem(strings.$GH_REFRESH_TOKEN);
    const response = await axios.post(`${constants.API_URL}/user/refresh`, { refresh_token });
    const token = response.data;

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    localStorage.setItem(strings.$GH_TOKEN, token);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: uid,
        token: token,
      })
    );
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem('userData');
    localStorage.removeItem(strings.$GH_TOKEN);
    localStorage.removeItem(strings.$GH_REFRESH_TOKEN);
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData') as string);
    const refresh_token = localStorage.getItem(strings.$GH_REFRESH_TOKEN) as string;
    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      login(storedData.userId, storedData.token, refresh_token);
    }
  }, [login]);

  return { token, login, logout, userId, refresh };
};
