import { ArrowDropDown, ArrowDropUp, Delete, Schedule } from '@mui/icons-material';
import {
	Box,
	Button,
	Collapse,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { DataGridProps } from '@mui/x-data-grid';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { useContext, useState } from 'react';
import constants from '../config/constants';
import { AlertContext } from '../context/alert.context';
import { getLocalizedErrorMessage } from '../utils/functions';
const Row = ({
	session,
	onPrivateSessionSetAppointment,
	refetchFunction,
}: {
	session: any;
	onPrivateSessionSetAppointment: (member_id: string, member_name: string, service: any) => void;
	refetchFunction: () => Promise<void>;
}) => {
	const alert = useContext(AlertContext);

	const handleCancelSession = async (session_id: string) => {
		try {
			await axios.delete(`${constants.API_URL}/member/private_service/appointment/`, {
				data: { slot_id: session_id, silent: false },
			});
			alert?.showAlert('המפגש בוטל', 'success');

			refetchFunction();
		} catch (error) {
			alert?.showAlert(getLocalizedErrorMessage(error as AxiosError, 'he'), 'error');
		}
	};
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<TableRow sx={{ '& > *, .css-fy78xn-MuiTableCell-root': { borderBottom: 'unset' } }} style={{}}>
				<TableCell size='small' style={{ maxWidth: 10 }}>
					<IconButton aria-label='expand row' size='small' onClick={() => setIsOpen((open) => !open)}>
						{isOpen ? <ArrowDropDown /> : <ArrowDropUp />}
					</IconButton>
				</TableCell>
				<TableCell size='small'>{moment(session.time).format('DD/MM/YY HH:mm')}</TableCell>
				<TableCell
					size='small'
					style={{ overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: 50, textOverflow: 'ellipsis' }}>{`${session.first_name?.slice(
					0,
					10
				)} ${session.last_name?.slice(0, 1)}`}</TableCell>
				<TableCell size='small' style={{ maxWidth: 240, overflow: 'hidden', textOverflow: 'ellipsis' }}>
					<div>
						<span style={{ fontSize: 14 }}>{`${session.service?.title?.he} `}</span>
						<span style={{ fontSize: 10, opacity: 0.6 }}>{`${session.followup_of ? 'פגישת המשך' : ''}`}</span>
					</div>
				</TableCell>

				<TableCell size='small' style={{ minWidth: 60, whiteSpace: 'nowrap' }}>{`${
					session?.price ? `${session.price || 0} ₪` : ''
				}`}</TableCell>
				<TableCell size='small' style={{ maxWidth: 40 }}>{`${session?.member?.company?.english_name || ''}`}</TableCell>
				<TableCell size='small'>{session.payment_confirm !== true ? 'ממתין לתשלום' : ''}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell size='small' style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={isOpen}>
						<Box sx={{ paddingInlineStart: { xs: 0, sm: 4 }, paddingBottom: 4, paddingTop: 2 }}>
							<Grid container spacing={1} mb={2}>
								<Grid item md={4}>
									<Typography display='inline' style={{ fontWeight: '600' }}>
										מייל:{' '}
									</Typography>
									<Typography display='inline'>
										<a href={`mailto:${session.email}`}>{`${session.email || '-'}`}</a>
									</Typography>
								</Grid>
								<Grid item md={2}>
									<Typography display='inline' style={{ fontWeight: '600' }}>
										טלפון:{' '}
									</Typography>
									<Typography display='inline'>{`${session.phone || '-'}`}</Typography>
								</Grid>
								<Grid item md={2}>
									<Typography display='inline' style={{ fontWeight: '600' }}>
										מסובסד:{' '}
									</Typography>
									<Typography display='inline'>{`${session.subsidized ? 'כן' : 'לא'}`}</Typography>
								</Grid>
								<Grid item md={2}>
									<Typography display='inline' style={{ fontWeight: '600' }}>
										שיחת המשך:{' '}
									</Typography>
									<Typography display='inline'>{`${session.followup_of ? 'כן' : 'לא'}`}</Typography>
								</Grid>
								<Grid item md={2}>
									<Typography display='inline' style={{ fontWeight: '600' }}>
										משך:{' '}
									</Typography>
									<Typography display='inline'>{`${session.duration} דקות`}</Typography>
								</Grid>
								{session.payment_confirm !== true && (
									<Grid item md={2}>
										<Typography style={{ fontWeight: '600' }}>סטטוס: </Typography>
										<Typography>{session.payment_confirm !== true ? 'ממתין לתשלום' : ''}</Typography>
									</Grid>
								)}
							</Grid>
							<Grid container spacing={2} justifyContent={'flex-end'}>
								<Grid item lg={2}>
									<Button
										color='error'
										disabled={!session.cancellable} //|| moment(session.time).isBefore(moment())}
										onClick={() => {
											handleCancelSession(session._id);
										}}
										fullWidth
										variant='outlined'
										style={{ display: 'flex', gap: 4 }}>
										<span>ביטול מפגש</span>
										<Delete />
									</Button>
								</Grid>
								<Grid item lg={3}>
									<Button
										// fullWidth
										disabled={!session.schedule_another}
										variant='contained'
										style={{ display: 'flex', gap: 6 }}
										onClick={() => {
											onPrivateSessionSetAppointment(session.member?._id, session?.first_name, session.service);
										}}>
										<span>קביעת מפגש נוסף</span>
										<Schedule />
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};
// type serviceTypesType = { '1TO1': string; SAFE_SPACE: string; HELPING_HAND: string };
// const serviceTypes: serviceTypesType = {
// 	'1TO1': 'אחד על אחד',
// 	SAFE_SPACE: 'המרחב הבטוח',
// 	HELPING_HAND: 'עזרה ראשונה',
// };
const PrivateSessionsTable = ({
	privateSessions = [],
	onPrivateSessionSetAppointment,
	refetchFunction,
	...props
}: {
	privateSessions?: any[];
	onPrivateSessionSetAppointment: (member_id: string, member_name: string, service: any) => void;
	isPsychologist?: boolean;
	refetchFunction: () => Promise<void>;
} & Partial<DataGridProps>) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	return (
		<div>
			<p style={{ display: 'flex', alignItems: 'center', paddingInline: 16 }}>
				<span>לקבלת פרטים נוספים על הפגישה לחצו על </span>
				<ArrowDropDown />
				<span>בשורה המתאימה</span>
			</p>
			<Box sx={{ overflow: 'auto' }}>
				<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
					<Table style={{ border: '0px' }}>
						<TableHead>
							<TableRow>
								<TableCell width={10} />
								<TableCell width={100}>מועד</TableCell>
								<TableCell>שם</TableCell>
								<TableCell>מפגש</TableCell>
								<TableCell width={50}>מחיר</TableCell>
								<TableCell width={100}>חברה</TableCell>
								<TableCell width={60}>סטטוס</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{privateSessions
								.sort((a, b) => moment(b.time).diff(a.time))
								.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
								.map((session) => (
									<Row
										session={session}
										onPrivateSessionSetAppointment={onPrivateSessionSetAppointment}
										refetchFunction={refetchFunction}
									/>
								))}
						</TableBody>
					</Table>

					<TablePagination
						component='div'
						count={privateSessions.length}
						page={page}
						onPageChange={(e, newPage) => {
							setPage(newPage);
						}}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={(e) => {
							setRowsPerPage(parseInt(e.target.value, 10));
							setPage(0);
						}}
					/>
				</Box>
			</Box>
		</div>
		// <DataGrid
		// 	style={{ direction: 'rtl' }}
		// 	sortModel={[{ field: 'time', sort: 'desc' }]}
		// 	pageSize={10}
		// 	autoPageSize
		// 	rowsPerPageOptions={[10, 25, 50]}
		// 	autoHeight
		// 	isCellEditable={() => false}
		// 	getRowId={(row) => row._id}
		// 	isRowSelectable={() => true}
		// 	columns={[
		// 		{
		// 			headerName: 'תאריך',
		// 			field: 'time',
		// 			valueFormatter: ({ value }) => moment(value).format('D/M/YY H:mm'),
		// 			minWidth: 120,
		// 			type: 'dateTime',
		// 		},
		// 		{
		// 			headerName: 'שם',
		// 			field: 'name',
		// 			valueGetter: ({ row }) =>
		// 				`${row.member?.first_name || row.first_name || ''} ${row.member?.last_name || row.last_name || '-'}`,
		// 		},
		// 		{ headerName: 'מייל', field: 'email', valueGetter: ({ row }) => row.member?.email, minWidth: 150 },

		// 		{ headerName: 'שם מפגש', field: 'title', valueGetter: ({ row }) => row.service?.title?.he },
		// 		{
		// 			hideable: true,
		// 			headerName: 'סוג',
		// 			field: 'type',
		// 			minWidth: 130,
		// 			valueGetter: ({ row }) => {
		// 				return serviceTypes[row.service?.type as keyof serviceTypesType] || 'אחר';
		// 			},
		// 		},

		// 		{
		// 			width: 70,
		// 			field: 'מחיר',
		// 			valueGetter: ({ row }) => row.price,

		// 			valueFormatter: ({ value }) => (value > 0 ? `${value} ₪` : ''),
		// 		},
		// 		{
		// 			width: 70,
		// 			renderHeader: (params) => <Typography style={{ fontSize: 14 }}>{params.colDef.field}</Typography>,
		// 			field: 'מסובסד?',
		// 			type: 'boolean',
		// 			valueGetter: ({ row }) => row.subsidized,
		// 			sortable: false,
		// 			disableColumnMenu: true,
		// 		},
		// 		{
		// 			width: 70,
		// 			align: 'right',
		// 			field: 'payment_confirm',
		// 			headerName: 'סטטוס',
		// 			sortable: false,
		// 			disableColumnMenu: true,
		// 			renderCell: ({ value }) => (
		// 				<Typography textAlign='center' fontSize={10} alignSelf={'center'}>
		// 					{value === true ? '' : 'ממתין לתשלום'}
		// 				</Typography>
		// 			),
		// 			// valueFormatter: ({ value }) => (value ? 'ממתין לתשלום' : ''),
		// 		},
		// 		{
		// 			field: 'duration',
		// 			valueGetter: ({ row }) => row.service?.duration,
		// 			valueFormatter: ({ value }) => `${value} ד'`,
		// 			width: 60,
		// 			headerName: 'משך',
		// 			disableColumnMenu: true,
		// 			sortable: false,
		// 		},
		// 		{
		// 			field: 'fu',
		// 			headerName: 'שיחת המשך?',
		// 			valueGetter: ({ row }) => (Boolean(row.followup_of) ? 'כן' : ''),
		// 			headerAlign: 'center',

		// 			disableColumnMenu: true,
		// 			sortable: false,
		// 		},
		// 		{
		// 			field: 'company',
		// 			headerName: 'חברה',
		// 			valueGetter: ({ row }) =>
		// 				row.member?.type?.includes('newborn') ? 'לידה' : row.member?.company?.english_name,
		// 			headerAlign: 'center',
		// 			align: 'center',

		// 			disableColumnMenu: true,
		// 			sortable: false,
		// 		},
		// 		{
		// 			field: 'reschedule',
		// 			headerName: 'קביעת מפגש נוסף',

		// 			disableColumnMenu: true,
		// 			sortable: false,
		// 			renderCell: (row) => (
		// 				<Tooltip title={row.row?.member && row.row.payment_confirm !== false ? 'קביעת מפגש נוסף' : 'לא זמין'}>
		// 					<span>
		// 						<Button
		// 							disabled={!(row.row?.member && row.row.payment_confirm !== false)}
		// 							onClick={() => {
		// 								onPrivateSessionSetAppointment(row.row.member._id, row.row?.member?.first_name, row.row['service']);
		// 							}}>
		// 							<CalendarMonth />
		// 						</Button>
		// 					</span>
		// 				</Tooltip>
		// 			),
		// 		},
		// 		{
		// 			field: 'ביטול\nמפגש',
		// 			disableColumnMenu: true,
		// 			sortable: false,
		// 			renderCell: (row) => (
		// 				// <Tooltip title='לא זמין כרגע'>
		// 				// 	<span>
		// 				<Button
		// 					disabled={!Boolean(row.row.member?._id)}
		// 					title='click'
		// 					color='error'
		// 					onClick={() => {
		// 						axios.delete(`${constants.API_URL}/member/private_service/appointment/`, {
		// 							data: { slot_id: row.row._id },
		// 						});
		// 					}}>
		// 					<Delete />
		// 				</Button>
		// 				// </span>
		// 				// </Tooltip>
		// 			),
		// 		},
		// 		// {
		// 		//   field: 'review',
		// 		//   headerName: 'שליחת טופס משוב',
		// 		//   disableColumnMenu: true,
		// 		//   sortable: false,
		// 		//   renderCell: (row) => (
		// 		//     <span>
		// 		//       <Button
		// 		//         disabled={!row.row.uuid}
		// 		//         color='success'
		// 		//         onClick={async () => {
		// 		//           try {
		// 		//             await axios.post(
		// 		//               `${constants.API_URL}/instructor/admin/private_session/send_review/${row.row._id}`,
		// 		//               {},
		// 		//               { params: { uuid: row.row.uuid } }
		// 		//             );
		// 		//             alert?.showAlert(`משוב למילוי נשלח ל${row.row.email}`, 'success');
		// 		//           } catch (error) {
		// 		//             alert?.showAlert(`לא הצלחנו`, 'error');
		// 		//           }
		// 		//         }}
		// 		//       >
		// 		//         שליחת משוב
		// 		//       </Button>
		// 		//     </span>
		// 		//   ),
		// 		// },
		// 	]}
		// 	rows={privateSessions || []}
		// 	{...props}
		// />
	);
};

export default PrivateSessionsTable;
