import { Alert, Snackbar } from '@mui/material';
import React, { useContext } from 'react';
import { AlertContext } from '../context/alert.context';

const AlertComp = () => {
  const alert = useContext(AlertContext);
  return (
    <Snackbar open={alert?.open}>
      <Alert color={alert?.type}>{alert?.message}</Alert>
    </Snackbar>
  );
};

export default AlertComp;
