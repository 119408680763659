import { Star } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import constants from '../../config/constants';
import useFetch from '../../hooks/useFetch';

const Reviews = () => {
	const { data } = useFetch<any[]>(`${constants.API_URL}/instructor/admin/review`);
	return (
		<div>
			<div style={{ paddingInline: 24 }}>
				<Table style={{ direction: 'rtl' }}>
					<TableBody>
						{data?.map((review: any) => (
							<TableRow>
								<TableCell>{review.createdAt ? moment(review.createdAt).format('DD/MM/YY') : ''}</TableCell>
								<TableCell>{review.text}</TableCell>
								<TableCell style={{ width: 250 }}>
									<span style={{ fontSize: 12 }}>{review.__t === 'Review:PrivateSession' ? 'פרטני' : review.meeting_title}</span>
								</TableCell>
								<TableCell>
									<div style={{ textAlign: 'center' }}>{review.rating}</div>
									<div style={{ flexDirection: 'row', display: 'flex', gap: 2, justifyContent: 'center' }}>
										{Array.from(Array(review.rating), (_, i) => (
											<Star style={{ color: 'gold', fontSize: 10 }} />
										))}
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		</div>
	);
};

export default Reviews;
