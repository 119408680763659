import { Box, Button, CircularProgress, Dialog, Typography } from '@mui/material';
import he from 'date-fns/locale/he';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';

registerLocale('he', he);
type AppointmentScheduleDialogProps = {
  title: string;
  duration?: number;
  onScheduleAppointmentButtonPressed: (selectedTime: Moment, isFollowup: boolean) => void;
  service?: any;
  open: boolean;
  onClose: () => void;
  loading?: boolean;
};

const AppointmentScheduleDialog = (props: AppointmentScheduleDialogProps) => {
  const [datetime, setDatetime] = useState<Date | null>(null);
  const [duration, setDuration] = useState<number>(props.duration || 20);
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Box p={2} height={450} width={350} textAlign={'center'} display={'flex'} flexDirection={'column'}>
        <Typography variant='h6'>{props.title}</Typography>
        <Box
          textAlign={'center'}
          py={1}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          flex={1}
        >
          <Box>
            <Box mb={1}>
              <Typography fontWeight={'600'}>משך המפגש</Typography>
              <Box display={'flex'} flexDirection='row' justifyContent='center' sx={{ direction: 'rtl' }}>
                <input
                  type='number'
                  step={5}
                  style={{ borderRadius: 10, borderWidth: 1, padding: 4, textAlign: 'center', fontFamily: 'Open Sans' }}
                  min={10}
                  max={120}
                  width={'100%'}
                  value={duration}
                  onChange={(e) => setDuration(Number.parseInt(e.target.value))}
                />
                <Typography px={1}>{'דקות'}</Typography>
              </Box>
            </Box>
            <Typography fontWeight={'600'}>מועד המפגש</Typography>

            <DatePicker
              timeCaption='שעה'
              timeIntervals={5}
              customInput={
                <input
                  style={{
                    textAlign: 'center',
                    borderWidth: 1,
                    borderRadius: 10,
                    paddingBlock: 4,
                    fontFamily: 'Open Sans',
                  }}
                />
              }
              dateFormat={'dd/MM/yy HH:mm'}
              timeFormat='HH:mm'
              locale={'he'}
              showTimeSelect
              selected={datetime}
              onChange={(e) => setDatetime(e)}
              minDate={new Date()}
            />
          </Box>
          <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
            <Button
              variant='contained'
              disabled={!datetime || !duration || props.loading}
              onClick={() => {
                if (datetime) props.onScheduleAppointmentButtonPressed(moment(datetime), false);
              }}
            >
              קביעת פגישה
            </Button>
            <Typography fontSize={14}>
              בקביעת מפגש בתשלום, יישלח למשתמש קישור תשלום הזמין ל-24 שעות. כאשר התשלום יבוצע המפגש ייקבע
            </Typography>
            {props.loading && <CircularProgress />}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AppointmentScheduleDialog;
