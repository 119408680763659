import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react';
export const AlertContext = createContext<{
  open: boolean;
  setIsOpen: (val: boolean) => void;
  message: string;
  setMessage: (v: string) => void;
  setType: (v: 'error' | 'success') => void;
  type: 'error' | 'success';
  showAlert: (message: string, type?: 'error' | 'success') => void;
} | null>(null);

const AlertProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  // this state will be shared with all components
  const [open, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<'error' | 'success'>('success');

  const showAlert = useCallback(
    (message: string, type: 'error' | 'success' = 'success') => {
      setType(type);
      setMessage(message);
      setIsOpen(true);
    },
    [setMessage, setIsOpen]
  );
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    }
  }, [open]);
  return (
    // this is the provider providing state
    <AlertContext.Provider value={{ open, setIsOpen, message, setMessage, showAlert, setType, type }}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
