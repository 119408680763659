import { Box, Button, Checkbox, Dialog, List, ListItem, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { IExpert, WorkingDay } from '../types';

import constants from '../config/constants';
import { AlertContext } from '../context/alert.context';

const WorkingHours = ({ profileData }: { profileData?: IExpert }) => {
	const [vacation, setVacation] = useState<{ start?: Date; end?: Date } | undefined>(profileData?.vacation);
	const [weekTimes, setWeekTimes] = useState<WorkingDay[]>([
		{ from: 0, to: 0, disabled: true },
		{ from: 0, to: 0, disabled: true },
		{ from: 0, to: 0, disabled: true },
		{ from: 0, to: 0, disabled: true },
		{ from: 0, to: 0, disabled: true },
		{ from: 0, to: 0, disabled: true },
		{ from: 0, to: 0, disabled: true },
	]);

	const alert = useContext(AlertContext);
	const [vacationError, setVacationError] = useState<string>('');
	const [showVacationDialog, setShowVacationDialog] = useState(false);
	const onClearVacation = async () => {
		await axios.delete(`${constants.API_URL}/instructor/admin/vacation`);
		alert?.showAlert('חופשה נמחקה בהצלחה');
	};
	const onUpdateVacation = async ({ start, end }: { start: Date; end: Date }) => {
		if (!start || !end) throw new Error('חובה להגדיר תאריך התחלה וסיום');
		if (start > end) {
			throw new Error('סיום החופשה חייב להיות לאחר תחילת החופשה');
		} else {
			await axios.post(`${constants.API_URL}/instructor/admin/vacation`, { start, end });

			alert?.showAlert('חופשה נקבעה בהצלחה');
		}
	};

	useEffect(() => {
		if (profileData?.weekly_times) setWeekTimes(profileData?.weekly_times.map((x) => ({ ...x, disabled: x.disabled ?? false })));
	}, [profileData?.weekly_times]);

	return (
		<>
			<Dialog
				open={showVacationDialog}
				onClose={() => {
					setShowVacationDialog(false);
				}}>
				<Box minWidth={250} p={4} pt={2}>
					<Box py={2}>
						<Typography variant='h5' textAlign={'center'}>
							חופשה
						</Typography>
					</Box>
					<Stack direction={'row'} gap={2} sx={{ direction: 'rtl' }}>
						<Stack>
							<Typography>התחלה</Typography>
							<TextField
								label
								type='date'
								value={vacation?.start ? moment(vacation?.start).format('YYYY-MM-DD') : undefined}
								onChange={(e) => {
									setVacationError('');
									setVacation((prev) => ({ ...prev, start: moment(e.target.value).toDate() }));
								}}
							/>
						</Stack>
						<Stack>
							<Typography>סיום</Typography>
							<TextField
								type='date'
								value={vacation?.end ? moment(vacation?.end).format('YYYY-MM-DD') : undefined}
								onChange={(e) => {
									setVacationError('');
									setVacation((prev) => ({ ...prev, end: moment(e.target.value).toDate() }));
								}}
							/>
						</Stack>
					</Stack>
					<Box display='flex' justifyContent='center'>
						<Typography variant='caption' textAlign={'center'} color='red'>
							{vacationError}
						</Typography>
					</Box>
					<Box justifyContent={'space-between'} display={'flex'} mt={2} gap={2}>
						<Button
							style={{ height: 30 }}
							variant='contained'
							onClick={async () => {
								try {
									if (!vacation?.start || !vacation?.end) throw new Error('חובה להגדיר תאריך התחלה וסיום');
									await onUpdateVacation({ start: vacation?.start, end: vacation?.end });
								} catch (e) {
									setVacationError((e as Error).message);
								}
							}}>
							הגדר
						</Button>
						<Button variant='outlined' onClick={onClearVacation} style={{ height: 30 }}>
							נקה
						</Button>
					</Box>
				</Box>
			</Dialog>
			<Box p={2}>
				<Box pb={2}>
					<List>
						<Typography px={2} variant='caption'>
							זמין/ה?
						</Typography>
						{[...Array(7)].map((_, i) => (
							<ListItem alignItems='center' key={i}>
								<Box flexDirection={'row'} display='flex' gap={2}>
									<Checkbox
										checked={weekTimes[i]?.disabled === false}
										onChange={(e) => {
											setWeekTimes((prev) => {
												const newState = prev.slice();
												newState[i].disabled = !e.target.checked;
												return newState;
											});
										}}
									/>
									<Typography textAlign={'right'} alignSelf='center' width={100}>
										{moment().locale('he').localeData().weekdays()[i]}
									</Typography>
									<TextField
										disabled={weekTimes[i]?.disabled}
										type='number'
										label='התחלה'
										variant='standard'
										value={weekTimes[i]?.from}
										onChange={(v) => {
											if (Number.parseInt(v.target.value) >= 0 && Number.parseInt(v.target.value) <= 24)
												setWeekTimes((prev) => {
													const newState = prev.slice();
													newState[i].from = Number.parseInt(v.target.value);
													return newState;
												});
										}}
									/>
									<TextField
										type='number'
										inputMode='numeric'
										label='סיום'
										variant='standard'
										disabled={weekTimes[i]?.disabled}
										value={weekTimes[i]?.to}
										onChange={(v) => {
											if (Number.parseInt(v.target.value) >= 0 && Number.parseInt(v.target.value) <= 24)
												setWeekTimes((prev) => {
													const newState = prev.slice();
													newState[i].to = Number.parseInt(v.target.value);
													return newState;
												});
										}}
									/>
								</Box>
							</ListItem>
						))}
					</List>
				</Box>
				<Box display={'flex'} alignItems={'center'} justifyContent='center'>
					<Button
						style={{ alignSelf: 'center' }}
						variant='contained'
						onClick={async () => {
							await axios.put(`${constants.API_URL}/instructor/admin/weekly_times`, {
								weekly_times: weekTimes,
							});

							alert?.showAlert('שעות הפעילות עודכנו');
						}}>
						עדכון שעות פעילות
					</Button>
				</Box>
				<Stack direction='row' gap={2} alignItems={'center'} my={3}>
					<Button variant='contained' onClick={() => setShowVacationDialog(true)}>
						הגדרת חופשה
					</Button>

					{vacation && (
						<Typography variant='body2' textAlign='center' whiteSpace={'pre-wrap'}>{`חופשה מוגדרת:\tהתחלה: ${moment(
							vacation.start
						).format('DD/MM/YY HH:mm')}\t\t\tסיום: ${moment(vacation.end).format('DD/MM/YY HH:mm')}`}</Typography>
					)}
				</Stack>
			</Box>

			{/* <Button onClick={() => setShowCalendar((prev) => !prev)}>הצג/סגור תצוגת לוח שנה</Button>
			<Collapse in={showCalendar} title='CLick'>
				<FreeBusyCalendar id={id} />
			</Collapse> */}
		</>
	);
};

export default WorkingHours;
