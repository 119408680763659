import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

const useFetch = <T extends any>(url: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>();

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios(url, {
        headers: { 'Content-Type': 'application/json' },
      });
      setData(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [url]);
  useEffect(() => {
    fetch();
  }, [url, fetch]);

  const refetch = fetch;

  return { loading, data, refetch };
};

export default useFetch;
