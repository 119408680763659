import { CancelOutlined, CheckOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Link, Stack, Typography } from '@mui/material';
import GoogleButton from 'react-google-button';
import useFetch from '../hooks/useFetch';

const GoogleCalendar = ({
  linked,
  onButtonClicked,
  checkSyncURL,
}: {
  linked: boolean;
  onButtonClicked: () => void;
  checkSyncURL: string;
}) => {
  const sync = useFetch<{ available: number }>(checkSyncURL);
  const available = sync.data?.available;

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={2} p={3}>
      <Typography textAlign={'center'} maxWidth={600}>
        In order to show users time slots open for booking, it is necessary to connect your account to Google Calendar.
        This sync will only take / show "free" / "busy" slots and is not showing any personal schedule and details.
      </Typography>
      <Typography textAlign={'center'} fontSize={14} maxWidth={600}>
        Group Hug's use and transfer of information received from Google APIs to any other app will adhere to{' '}
        <Link href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>
          Google API Services User Data Policy
        </Link>
        , including the Limited Use requirements
      </Typography>

      <GoogleButton onClick={onButtonClicked} />
      {available ? (
        <Stack display='flex' direction={'column'} alignItems='center' gap={2}>
          {available === 200 ? <CheckOutlined color='success' /> : <CancelOutlined color='error' />}
          <Typography
            style={{ whiteSpace: 'pre-wrap' }}
            textAlign='center'
            fontWeight={600}
            color={available === 200 ? 'black' : 'error'}>
            {available === 200
              ? 'מחובר'
              : available === 405
              ? 'הסינכרון לא הושלם בהצלחה\nיש לבצע סינכרון מחדש ולוודא שמסמנים את תיבות הסימון המאשרות גישה ליומן'
              : 'לא מחובר'}
          </Typography>
        </Stack>
      ) : sync.loading ? (
        <CircularProgress size={20} />
      ) : null}
    </Box>
  );
};

export default GoogleCalendar;
