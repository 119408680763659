import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { useAuth } from './auth/auth.service';

import { ThemeProvider, createTheme } from '@mui/material';
import Alert from './components/Alert';
import AlertProvider from './context/alert.context';
import UserContext from './context/user.context';
import Login from './pages/Login';
import Main from './pages/Main';
import ResetPassword from './pages/ResetPassword';
import { useEffect } from 'react';
import axios from 'axios';

require('./config/he.locale');
function App() {
	const { token, login, logout, userId } = useAuth();

	const theme = createTheme({
		typography: { allVariants: { fontFamily: 'Open Sans', direction: 'rtl' } },
		components: {
			MuiTableCell: { defaultProps: { align: 'right' } },
			MuiTextField: {
				styleOverrides: {
					root: {
						'& label': {
							transformOrigin: 'right !important',
							left: 'inherit !important',
							right: '0rem !important',
							fontSize: 'small',
							color: '#807D7B',
							fontWeight: 400,
							overflow: 'unset',
						},
					},
				},
			},
		},
	});
	useEffect(() => {
		axios.defaults.headers.common['x-client'] = 'experts-web';
	}, []);
	return (
		<ThemeProvider theme={theme}>
			<AlertProvider>
				<UserContext.Provider value={{ isLoggedIn: !!token, token, userId, login, logout }}>
					<BrowserRouter>
						<Routes>
							<Route path='/login' element={<Login />} />
							{userId && <Route path='/expert' element={<Main />} />}
							<Route path='/reset_password' element={<ResetPassword />} />
							<Route path='*' element={<Login />} />
						</Routes>
					</BrowserRouter>
				</UserContext.Provider>
				<Alert />
			</AlertProvider>
		</ThemeProvider>
	);
}

export default App;
