import { Card, CardHeader } from '@mui/material';
import React from 'react';
import MeetingsTable from '../MeetingsTable';
import MeetingServicesTable from '../MeetingServicesTable';
import moment from 'moment';
import useFetch from '../../hooks/useFetch';
import constants from '../../config/constants';
import { IService } from '../../types';

const GroupSessionsTab = () => {
	const meetings = useFetch<any[]>(`${constants.API_URL}/instructor/admin/meetings`);
	const { data: services } = useFetch<IService[]>(`${constants.API_URL}/instructor/admin/services`);

	return (
		<div>
			<Card dir='rtl' style={{ marginTop: 20 }}>
				<CardHeader title='מפגשים קבוצתיים עתידיים' />
				<MeetingsTable
					showZoomInfo
					loading={meetings.loading}
					rows={meetings.data?.filter((x) => moment(x.time).isAfter(moment())) || []}
					initialState={{ sorting: { sortModel: [{ field: 'time', sort: 'asc' }] } }}
				/>
			</Card>
			<Card dir='rtl' style={{ marginTop: 20 }}>
				<CardHeader title='מפגשי עבר קבוצתיים' />
				<MeetingsTable
					loading={meetings.loading}
					rows={meetings.data?.filter((x) => moment(x.time).isSameOrBefore(moment())) || []}
					sortModel={[{ field: 'time', sort: 'desc' }]}
				/>
			</Card>
			<Card dir='rtl' style={{ marginTop: 20 }}>
				<CardHeader title='שירותים קבוצתיים' />
				<MeetingServicesTable services={services} />
			</Card>
		</div>
	);
};

export default GroupSessionsTab;
