import { CloseOutlined } from '@mui/icons-material';
import { Alert, Collapse, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../config/constants';

export default function ResetPassword() {
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      await axios.post(`${constants.API_URL}/member/forgot_password`, {
        email: data.get('email'),
      });
      setIsSuccess(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error: any) {
      if (error?.response?.data?.errors)
        setErrors(
          error.response.data.errors
            ?.map((error: any) => {
              return error['he'];
            })
            ?.join('\n')
        );
      else setErrors([error.message]);
    }
  };

  return (
    <>
      <Collapse in={errors?.length > 0}>
        <Alert
          severity='error'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setErrors([]);
              }}
            >
              <CloseOutlined fontSize='inherit' />
            </IconButton>
          }
        >
          {errors}
        </Alert>
      </Collapse>
      {/* <ThemeProvider theme={theme}> */}
      <Container dir='rtl' component='main' maxWidth='xs'>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, backgroundColor: 'secondary.main' }} src={require('../assets/grouphug_1024.png')} />

          <Typography component='h1' variant='h5'>
            איפוס סיסמה
          </Typography>

          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
            />

            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              איפוס
            </Button>
            {isSuccess && (
              <Box>
                <Typography variant='h4' textAlign={'center'}>
                  שלחנו לך אימייל לאיפוס הסיסמה!
                </Typography>
                <Typography variant='body1' textAlign={'center'}>
                  מיד נעביר אותך למסך ההתחברות
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
      {/* </ThemeProvider> */}
    </>
  );
}
