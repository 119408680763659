import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

type Props = {
	onNextMonthClick: () => void;
	onPrevMonthClick: () => void;
	onResetMonthClick: () => void;
	month: number;
	year: number;
};
const MonthSelectionBox = (props: Props) => {
	return (
		<Box width='max-content' display='flex' alignSelf={'flex-end'} flexDirection={'column'} alignItems={'center'}>
			<Stack gap={2} direction={'row'} alignItems={'center'}>
				<IconButton onClick={props.onNextMonthClick}>
					<ArrowBack />
				</IconButton>
				<Typography>{`${props.month + 1}/${props.year}`}</Typography>
				<IconButton onClick={props.onPrevMonthClick}>
					<ArrowForward />
				</IconButton>
			</Stack>
			<div>
				<Button onClick={props.onResetMonthClick}>איפוס לחודש נוכחי</Button>
			</div>
		</Box>
	);
};

export default MonthSelectionBox;
