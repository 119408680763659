import { AppBar, Box, Button, Card, CardHeader, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExpertProfile from '../components/ExpertProfile';
import GoogleCalendar from '../components/GoogleCalendar';
import TabPanel from '../components/TabPanel';
import WorkingHours from '../components/WorkingHours';
import GroupSessionsTab from '../components/tabs/GroupSessionsTab';
import OneToOneSessionsTab from '../components/tabs/OneToOneSessionsTab';
import Payments from '../components/tabs/Payments';
import constants from '../config/constants';
import userContext from '../context/user.context';
import useFetch from '../hooks/useFetch';
import { IExpert } from '../types';
import Reviews from '../components/tabs/Reviews';

const Main = () => {
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(0);
	const auth = useContext(userContext);
	const profile = useFetch<IExpert>(`${constants.API_URL}/instructor/admin/profile`);

	return (
		<>
			<AppBar position='fixed' style={{ backgroundColor: 'white' }} elevation={2} dir='rtl'>
				<Toolbar style={{ minHeight: 0, paddingBlock: 4 }}>
					<Box px={2}>
						<img alt='grouphug-logo' src='/images/grouphug-wide-logo.png' height={30} />
					</Box>
					<Typography variant='h5' color='InfoText' sx={{ flexGrow: 1 }}>
						{profile?.data?.name || 'שניה, טוענים...'}
					</Typography>
					<Button
						color='error'
						onClick={() => {
							auth.logout();
							navigate('/');
						}}>
						התנתק
					</Button>
				</Toolbar>
			</AppBar>
			<Container style={{ paddingBlockEnd: 20, paddingBlockStart: 44 }} maxWidth='lg'>
				<Box textAlign={'center'}></Box>
				<Tabs
					variant='fullWidth'
					style={{ direction: 'rtl' }}
					centered
					value={activeTab}
					onChange={(e, v) => setActiveTab(v)}
					textColor='primary'>
					<Tab label='מפגשים ושירותים קבוצתיים' />
					<Tab label='מפגשים פרטניים' />
					<Tab label='הפרופיל שלי' iconPosition='start' />
					<Tab label='זמני פעילות' />
					<Tab label='סינכרון יומן' />
					<Tab label='תשלומים' />
					<Tab label='משובים' />
				</Tabs>
				<TabPanel value={activeTab} index={0}>
					<GroupSessionsTab />
				</TabPanel>
				<TabPanel index={1} value={activeTab}>
					<OneToOneSessionsTab profileData={profile.data} />
				</TabPanel>
				<TabPanel index={2} value={activeTab}>
					<>
						<Card dir='rtl' style={{ marginTop: 20 }}>
							<CardHeader title='פרופיל מומחה' />
							<ExpertProfile profileData={profile.data} />
						</Card>
					</>
				</TabPanel>
				<TabPanel index={3} value={activeTab}>
					<Card dir='rtl'>
						<CardHeader title='שעות הפעילות שלי' />
						<WorkingHours profileData={profile.data} />
					</Card>
				</TabPanel>
				<TabPanel index={4} value={activeTab}>
					<Box p={2}>
						<GoogleCalendar
							checkSyncURL={`${constants.API_URL}/instructor/${profile.data?._id}/check_google`}
							linked={Boolean(profile?.data?.google?.access_token)}
							onButtonClicked={() => {
								window.open(`${constants.API_URL}/instructor/google/${profile?.data?._id}`);
							}}
						/>
					</Box>
				</TabPanel>
				<TabPanel index={5} value={activeTab}>
					<Payments />
				</TabPanel>
				<TabPanel index={6} value={activeTab}>
					<Reviews />
				</TabPanel>
			</Container>
		</>
	);
};
export default Main;
