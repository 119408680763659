import { Accordion, AccordionSummary, Box, Button, Stack, TableProps, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import axios, { AxiosError } from 'axios';
import { useContext, useState } from 'react';
import constants from '../config/constants';
import { AlertContext } from '../context/alert.context';
import { getLocalizedErrorMessage } from '../utils/functions';

const DESCRIPTION_LIMIT = 200;
const PrivateServiceItem = (props: { service: any; refetch: () => void }) => {
  const [service, setService] = useState<any>(props.service);
  const [isTouched, setIsTouched] = useState(false);

  const alert = useContext(AlertContext);
  return (
    <Accordion>
      <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }} fontWeight={800}>
          {service.title.he}
        </Typography>
      </AccordionSummary>
      <Grid container spacing={2} p={2}>
        <Grid xs={12} md={2}>
          <TextField label='כותרת עברית' value={service.title.he} variant='standard' disabled fullWidth></TextField>
          <TextField label='כותרת אנגלית' disabled value={service.title.en} variant='standard' fullWidth></TextField>
        </Grid>
        <Grid xs={12} md={5}>
          <TextField
            helperText={`${service.description?.he?.length || 0}/${DESCRIPTION_LIMIT}`}
            multiline
            inputProps={{ maxLength: DESCRIPTION_LIMIT }}
            label='תיאור עברית'
            value={service.description?.he}
            onChange={(e) => {
              setService((prev: any) => ({ ...prev, description: { ...prev.description, he: e.target.value } }));
              setIsTouched(true);
            }}
            variant='standard'
            fullWidth
          >
            {service.description?.he}
          </TextField>
          <TextField
            multiline
            helperText={`${service.description?.en?.length || 0}/${DESCRIPTION_LIMIT}`}
            inputProps={{ maxLength: DESCRIPTION_LIMIT }}
            label='תיאור אנגלית'
            value={service.description?.en}
            onChange={(e) => {
              setService((prev: any) => ({ ...prev, description: { ...prev.description, en: e.target.value } }));
              setIsTouched(true);
            }}
            variant='standard'
            fullWidth
          >
            {service.description?.en}
          </TextField>
        </Grid>
        <Grid xs={12} md={1} alignItems={'center'}>
          <Box height='100%' display='flex' justifyContent={'center'} alignItems={'center'}>
            {service.public && `ציבורי`}
          </Box>
        </Grid>
        <Grid xs={12} md={2}>
          <Stack
            gap={1}
            height='100%'
            display='flex'
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {service.price_before_discount ? (
              <Typography
                variant='body2'
                style={{ textDecoration: 'line-through' }}
              >{`${service.price_before_discount} ש"ח`}</Typography>
            ) : null}
            <Typography variant='body2'>{`${service.price} ש"ח`}</Typography>
            {service.duration ? <Typography variant='body2' mt={1}>{`${service.duration} דקות`}</Typography> : null}
          </Stack>
        </Grid>
        <Grid md={2} xs={12} justifyContent={'center'} display='flex' alignItems={'center'}>
          <TextField
            type='number'
            variant='standard'
            inputProps={{ style: { textAlign: 'center' }, step: 5, min: 0 }}
            sx={{ width: 100 }}
            InputLabelProps={{ style: { textAlign: 'center' } }}
            FormHelperTextProps={{ style: { textAlign: 'center' } }}
            label='הפסקה לאחר מפגש'
            helperText='דקות'
            value={service.gap_duration}
            onChange={(e) => {
              setService((prev: any) => ({ ...prev, gap_duration: +e.target.value }));
              setIsTouched(true);
            }}
          />
        </Grid>
        <Box width={'100%'} />
        <Grid xs={12} md={2}>
          <Box height='100%'>
            <Button
              disabled={!isTouched}
              onClick={async () => {
                try {
                  const { _id, title, description, gap_duration } = service;
                  const response = await axios.put(`${constants.API_URL}/instructor/admin/private_services`, {
                    _id,
                    title,
                    description,
                    gap_duration,
                  });
                  setIsTouched(false);

                  setService(response.data);
                  props.refetch();
                  alert?.showAlert('השירות עודכן');
                } catch (error) {
                  if (error instanceof AxiosError) {
                    alert?.showAlert(getLocalizedErrorMessage(error, 'he') || 'משהו השתבש', 'error');
                  }
                  alert?.showAlert('משהו השתבש', 'success');
                }
              }}
            >
              שמור
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Accordion>
  );
};
const PrivateServicesTable = ({
  privateSessions = [],
  ...props
}: { privateSessions?: any[]; refetch: () => void } & Partial<TableProps>) => {
  return (
    <Box width={'100%'} display='flex' flexDirection='column'>
      {privateSessions?.map((service, index) => (
        <PrivateServiceItem service={service} key={`private-session-row#${index}`} refetch={props.refetch} />
      ))}
    </Box>
  );
};

export default PrivateServicesTable;
