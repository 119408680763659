import { Table, TableBody, TableCell, TableHead, TableProps, TableRow, Typography } from '@mui/material';
import { IService } from '../types';

const MeetingServicesTable = ({ services = [], ...props }: { services?: IService[] } & Partial<TableProps>) => {
  return (
    <Table sx={{ direction: 'rtl' }} {...props}>
      <TableHead dir='rtl' style={{ textAlign: 'right' }}>
        <TableRow>
          <TableCell align='right'>שם</TableCell>
          <TableCell align='right'>תיאור</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {services?.map((item, index) => (
          <TableRow key={`service-row#${index}`}>
            <TableCell>
              <Typography textAlign={'right'} variant='body2'>
                {item.title}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography textAlign={'right'} variant='body1'>
                {item.description}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MeetingServicesTable;
