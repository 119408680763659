import {
	Box,
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useReducer } from 'react';
import constants from '../../config/constants';
import MonthSelectionBox from './MonthSelectionBox';
import { initialState, reducer } from './reducer';
const Payments = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { payments } = state;
	useEffect(() => {
		const fetchPayments = async () => {
			try {
				dispatch({ type: 'CLEAR_ERROR' });
				dispatch({ type: 'START_LOADING' });
				const res = await axios.get(`${constants.API_URL}/instructor/admin/private_appointments/summary`, {
					params: { filter: state.date },
				});
				dispatch({ type: 'SET_PAYMENTS', payload: res.data });
			} catch (error) {
				dispatch({ type: 'SET_IS_ERROR' });
			} finally {
				dispatch({ type: 'FINISH_LOADING' });
			}
		};
		fetchPayments();
	}, [state.date]);
	const refreshPayments = async () => {
		const res = await axios.get(`${constants.API_URL}/instructor/admin/private_appointments/summary/update`, {
			params: { filter: state.date },
		});
		dispatch({ type: 'SET_PAYMENTS', payload: res.data });
		dispatch({ type: 'CLEAR_ERROR' });
	};
	const paymentRows: { title: string; count?: number; price?: number; info?: string }[] = [
		{
			title: 'שיחות הכוונה',
			count: state.payments?.consultationSessions.count,
			price: state.payments?.consultationSessions.price,
		},
		{
			title: 'עזרה ראשונה רגשית',
			count: state.payments?.helpingHand.count,
			price: state.payments?.helpingHand.price,
		},
		{
			title: 'מפגשים פרטניים',
			count: state.payments?.oneToOneSessions.count,
			price: state.payments?.oneToOneSessions.price,
			info: 'כולל 3% עמלת סליקה',
		},
		{
			title: 'מפגשים קבוצתיים',
			count: state.payments?.groupSessions.count,
			price: state.payments?.groupSessions.price,
			info: state.payments?.groupSessions.info,
		},
		{
			title: 'מרחב בטוח',
			count: state.payments?.safe_space.count,
			price: state.payments?.safe_space.price,
			info: state.payments?.safe_space.info,
		},
	];
	return (
		<Box pt={3}>
			<MonthSelectionBox
				onResetMonthClick={() => {
					dispatch({ type: 'SET_DATE', padyload: new Date() });
				}}
				onNextMonthClick={() => {
					dispatch({ type: 'NEXT_MONTH' });
				}}
				onPrevMonthClick={() => {
					dispatch({ type: 'PREV_MONTH' });
				}}
				month={state.date.month}
				year={state.date.year}
			/>

			<Box textAlign='center'>
				{state.loading ? (
					<CircularProgress />
				) : state.isError ? (
					<Box>
						<h2>{`עדיין אין נתונים לחודש ${state.date.month + 1}/${state.date.year}`}</h2>
						<Button onClick={refreshPayments}>עדכון נתונים</Button>
					</Box>
				) : (
					<div>
						<Table style={{ direction: 'rtl' }}>
							<TableHead>
								<TableRow>
									<TableCell width={200}></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{paymentRows.map((row) => (
									<TableRow>
										<TableCell>{row.title}</TableCell>
										<TableCell>{row.count}</TableCell>
										<TableCell>{`${row.price}\t₪`}</TableCell>
										<TableCell>{row.info}</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TableCell>סה"כ</TableCell>
									<TableCell></TableCell>
									<TableCell>{`${payments?.total?.price || 0}\t₪`}</TableCell>
									<TableCell />
								</TableRow>
								<TableRow>
									<TableCell colSpan={4}>
										<Typography variant='caption'>עוסק מורשה - יש להוסיף מע"מ עבור כל השירותים למעט מפגשים פרטניים</Typography>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
						<div
							style={{
								direction: 'rtl',
								display: 'flex',
								alignItems: 'center',
							}}>
							<span style={{ marginInlineEnd: 20 }}>
								<Typography variant='caption'>{`עדכון אחרון: ${moment(state.payments?.updatedAt).fromNow()}`}</Typography>
							</span>
							<span>
								<Tooltip title={moment().diff(state.payments?.updatedAt, 'hour') < 1 ? 'ניתן לעדכן רק פעם בשעה' : ''}>
									<span>
										<Button
											size='small'
											onClick={refreshPayments}
											disabled={moment().diff(state.payments?.updatedAt, 'hour') < 1}>
											עדכון נתונים
										</Button>
									</span>
								</Tooltip>
							</span>
						</div>
					</div>
				)}
			</Box>
		</Box>
	);
};

export default Payments;
